<template>
  <div class="home">
    <v-card elevation="0">

      <v-card-title>Home Page</v-card-title>
      <v-card-subtitle>Quick access to various programs</v-card-subtitle>
      <v-card-actions>
        <ProgramList />
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
// @ is an alias to /src
import ProgramList from '@/components/common/ProgramList.vue';

export default {
  name: 'Home',
  components: {
    ProgramList,
  },
};
</script>
